import React from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ServiceItem } from '../components/serviceItem';
import imageBanner from '../assets/images/rental-car-service.png';
import shuttleMap01 from '../assets/images/lga-airport-map-01.png';
import shuttleMap02 from '../assets/images/rental-car-detour.png';
import { carRentalCompanies } from '../assets/objects/carRentalObjects';

const RentalCarService = () => {

  return (
    <React.Fragment>
      <div className='p-page'>
        <img
          className='p-page-header-image'
          src={imageBanner}
          alt={'Rental car service'}
        />
        <div className='p-page-content'>
          <h1 className='p-page-header'><Trans i18nKey='rentalCarServicesHeader' /></h1>
          <div className='p-page-description'><Trans i18nKey='rentalCarServicesDescription' /></div>
          <div className='p-page-description l-margin_top35'><Trans i18nKey='rentalCarServicesContactDescription' /></div>
          <h2 className='p-page-subheader'><Trans i18nKey='rentalCarCompanies' /></h2>
          <div className='p-page-flex-box l-margin_top35'>
            {carRentalCompanies.map((carCompany) =>
              <ServiceItem key={carCompany.name} name={carCompany.name} phone={carCompany.phone} link={carCompany.link} />)}
          </div>

          <h1 className='p-page-header l-margin_top95'><Trans i18nKey='shuttleRoutes' /></h1>
          <div className='p-page-flex-box p-page-shuttle-map-container'>
            <div className='p-page-flex-routes-item'>
              <div className='p-page-flex-routes-item-text c-height__100'><Trans i18nKey='shuttleRoutesTerminalB' /></div>
              <div><img className='p-page-flex-routes-item-image' src={shuttleMap01} alt={'Shuttle Route B'} /></div>
            </div>
            <div className='p-page-flex-routes-item'>
              <div className='p-page-flex-routes-item-text c-height__100'><Trans i18nKey='shuttleRoutesTerminalA' /></div>
              <div><img className='p-page-flex-routes-item-image' src={shuttleMap02} alt={'Shuttle Route A'} /></div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(RentalCarService);
