import React from 'react';
import { withRouter } from 'react-router-dom';
import ButtonGrid from '../components/buttonGrid';
import { Destinations } from '../utils/destinations';

class Transit extends React.Component {
  render() {
    return (
      <ButtonGrid
        links={[
          {
            titleKey: 'publicTransportation',
            to: {
              pathname: '/transit/external',
              state: {
                destinationUrl: Destinations.publicTransportation,
              },
            },
            svgName: 'tile-transit',
          },
          {
            titleKey: 'onAirportBus',
            to: {
              pathname: '/transit/external',
              state: {
                destinationUrl: Destinations.onAirportBusServices,
              },
            },
            svgName: 'tile-airport-shuttle',
          },
          {
            titleKey: 'airportShuttle',
            to: '/transit/airport-shuttle',
            svgName: 'tile-airport-shuttle',
          },
          {
            titleKey: 'rentalCars',
            to: '/transit/rental-car-service',
            svgName: 'tile-car-rental',
          },
          {
            titleKey: 'carServices',
            to: '/transit/car-service',
            svgName: 'tile-car-service',
          },
          {
            titleKey: 'taxis',
            to: '/transit/taxi-service',
            svgName: 'tile-taxi',
          },
        ]}
        buttonColorStyle='background-color-green'
      />
    );
  }
}

export default withRouter(Transit);
