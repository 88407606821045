import React from 'react';
import { useHistory } from 'react-router-dom';
import wholeNewLGAlogo from '../../assets/images/new_whole_log_logo.png';
import { Destinations } from '../../utils/destinations';

export default function NewAirportTile() {
  const history = useHistory();

  const route = {
    pathname: '/external',
    state: {
      destinationUrl: Destinations.newLGAAirport,
    }
  }

  const handleClick = (event) => {
    event.preventDefault();
    history.push(route);
  }

  return (
    <div role='button' onClick={handleClick} className='o-airport-link-button l-position__relative c-service-item-link'>
      <img
        className='c-new-airport-banner-logo'
        src={wholeNewLGAlogo}
        alt={'A whole new LGA'}
      />
    </div>
  );
}
