import React from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';

export const ServiceItem = (props) => {
  const { name, phone, link, singleItem } = props;
  const history = useHistory();

  const route = {
    pathname: '/transit/external',
    state: {
      destinationUrl: link,
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    history.push(route);
  }

  return (
    <div className='c-service-item-flex'>
      <div className='c-service-item-name'>
        <div role='button' onClick={handleClick} className='c-service-item-link'><Trans i18nKey={name} /></div>
      </div>
      <div className={singleItem ? 'c-service-item-phone-single-item' : 'c-service-item-phone'}>{phone}</div>
    </div>
  );
}
