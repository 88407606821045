
import strings from '../../i18n/en'
import { getKeyByValue } from '../../utils/utils'

const translations = strings.translations;

export const privateCarServices = [
    {
        name: getKeyByValue(translations, translations.privateCarServicesCarmelSuperSaver),
        phone: '1 (800) 924-9954',
        link: 'https://www.carmellimo.com/'
    }
] 