export const Destinations = {
    feedback: 'https://laguardiaairport.com/feedback/',
    publicTransportation: 'https://laguardiaairport.com/to-from-airport/public-transportation',
    onAirportBusServices: 'https://laguardiaairport.com/at-airport/on-airport-bus',
    bookRide: 'https://lga.gtisnext.com/kiosk',
    reviewReservation: 'https://lga.gtisnext.com/welcome',
    stay: 'https://www.booking.com/index.html?prefer_site_type=tdot',
    placesToGo: 'https://www.iloveny.com/places-to-go/',
    thingsToDo: 'https://www.iloveny.com/things-to-do/',
    events: 'https://www.iloveny.com/events/',
    planYourTrip: 'https://www.iloveny.com/travel-tools/',
    newLGAAirport: 'https://www.anewlga.com/',
    flightTracker: 'https://www.laguardiaairport.com/flight-search',
    lostAndFound: 'https://panynj.lostandfoundsite.com/LostItem.aspx?id=183dc70a494e495ba941c348ae4ad46d&sid=d74596a84ccb4d7e8284e7e0db5f4269',
    airlineDirectory: 'https://laguardiaairport.com/flight/airlines',
    airportMap: 'https://laguardiaairport.com/at-airport/airport-maps',
    parking: 'https://laguardiaairport.com/to-from-airport/parking',
    shopsAndRestaurants: 'https://laguardiaairport.com/at-airport/shops-restaurants-and-services'
}