
import strings from '../../i18n/en'
import { getKeyByValue } from '../../utils/utils'

const translations = strings.translations;

export const expressBusServices = [
    {
        name: getKeyByValue(translations, translations.expressBusServicesNYCExpressBus),
        phone: '1 (718) 777-5111',
        link: 'https://www.nycairporter.com/'
    }
] 