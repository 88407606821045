export const HttpService = (request, requestOptions) => {
    return new Promise((resolve, reject) => {
        fetch(request, requestOptions)
            .then(response => {
                resolve(response.json());
            })
            .catch(err => {
                reject(err);
            })
    });
}
