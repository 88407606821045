import React from "react";
import ButtonGrid from '../components/buttonGrid';
import { COLORS } from '../constants/appConstants';
import { Destinations } from '../utils/destinations';

export const Information = () => (
  <ButtonGrid
    links={[
      {
        titleKey: 'flightTracker',
        to: {
          pathname: '/information/external',
          state: {
            destinationUrl: Destinations.flightTracker,
          },
        },
        svgName: 'tile-flight-tracker',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'lostAndFound',
        to: {
          pathname: '/information/external',
          state: {
            destinationUrl: Destinations.lostAndFound,
          },
        },
        svgName: 'tile-lost-and-found',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'airlineDirectory',
        to: {
          pathname: '/information/external',
          state: {
            destinationUrl: Destinations.airlineDirectory,
          },
        },
        svgName: 'tile-airline-directory',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'airportMap',
        to: {
          pathname: '/information/external',
          state: {
            destinationUrl: Destinations.airportMap,
          },
        },
        svgName: 'tile-airport-map',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'parking',
        to: {
          pathname: '/information/external',
          state: {
            destinationUrl: Destinations.parking,
          },
        },
        svgName: 'tile-parking',
        svgFill: COLORS.BLACK,
      },
      {
        titleKey: 'shopsAndRestaurants',
        to: {
          pathname: '/information/external',
          state: {
            destinationUrl: Destinations.shopsAndRestaurants,
          },
        },
        svgName: 'tile-shops-and-restaurants',
        svgFill: COLORS.BLACK,
      },
    ]}
    buttonColorStyle='background-color-yellow'
  />
);
