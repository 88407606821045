
import strings from '../../i18n/en'
import { getKeyByValue } from '../../utils/utils'

const translations = strings.translations;

export const carRentalCompanies = [
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesAdvantage),
        phone: '1 (800) 777-5500',
        link: 'https://www.advantage.com/'
    },
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesAlamo),
        phone: '1 (888) 233-8749',
        link: 'https://www.alamo.com/en_US/car-rental/home.html'
    },
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesAvis),
        phone: '1 (800) 230-4898',
        link: 'https://www.avis.com/en/home'
    },
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesBudget),
        phone: '1 (718) 639-6400',
        link: 'https://www.budget.com/en/home'
    },
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesDollar),
        phone: '1 (800) 800-4000',
        link: 'https://www.dollar.com/'
    },
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesEnterprise),
        phone: '1 (800) 736-8222',
        link: 'https://www.enterprise.com/car_rental/home.do'
    },
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesHertz),
        phone: '1 (800) 654-3131',
        link: 'https://www.hertz.com/rentacar/reservation/'
    },
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesNational),
        phone: '1 (888) 826-6890',
        link: 'https://www.nationalcar.com/'
    },
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesPayless),
        phone: '1 (800) 729-5377',
        link: 'https://www.paylesscar.com/'
    },
    {
        name: getKeyByValue(translations, translations.rentalCarCompaniesThrifty),
        phone: '1 (800) 331-3550',
        link: 'https://www.thrifty.com/'
    },
] 