import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

export default function RouteWrapper({
  component: Component,
  ...otherProps
}) {
  // Any redirect logic would go here, e.g.
  /*
  if (condition) {
    return <Redirect to="/" />;
  }
  */

  // If no redirect, go to requested comment
  return <Route {...otherProps} component={Component} />;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
  isPrivate: false
};
