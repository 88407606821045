import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const BottomLegalBar = (props) => {
  const { t } = useTranslation();

  return (
    <div className="c-bottom-legal-bar">
      <span className="t-base__18">
        {t('piiDisclaimer')}
      </span>
      <span className="t-base__18">
        {t('piiWillBeDestroyed')}
      </span>
    </div>
  );
};
