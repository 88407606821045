import React from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import imageBanner from '../assets/images/taxi.png';
import airportMap from '../assets/images/lga-airport-map.png';
import airportLegends from '../assets/images/airport-legends.png';

const TaxiService = () => {

  return (
    <React.Fragment>
      <div className='p-page'>
        <img
          className='p-page-header-image'
          src={imageBanner}
          alt={'Taxi service'}
        />
        <div className='p-page-content'>
          <h1 className='p-page-header'><Trans i18nKey='taxiServicesHeader' /></h1>
          <div className='p-page-description'><Trans i18nKey='taxiServicesDescription' /></div>
          <div className='p-page-description l-margin_top35'><span className='bold'><Trans i18nKey='safetyTip' />:</span> <Trans i18nKey='taxiServicesSafetyTipDescription' /></div>
          <div className='p-page-description l-margin_top35'><Trans i18nKey='taxiServicesInformation' /></div>
          <ul>
            <li className='p-page-description'><Trans i18nKey='taxiServicesInformationFirstBullet' /></li>
            <li className='p-page-description'><Trans i18nKey='taxiServicesInformationSecondBullet' /></li>
            <li className='p-page-description'><Trans i18nKey='taxiServicesInformationThirdBullet' /></li>
            <li className='p-page-description'><Trans i18nKey='taxiServicesInformationFourthBullet' /></li>
            <li className='p-page-description'><Trans i18nKey='taxiServicesInformationFifthBullet' /></li>
            <li className='p-page-description'>
              <Trans i18nKey='taxiServicesInformationSixthBullet' />: <span className="fakeLink"><Trans i18nKey='NYCTaxiLimousineCommissionUrl' /></span> <Trans i18nKey='or' /> <span className="bold"><Trans i18nKey='NYCTaxiLimousineCommissionNumber' /></span>
            </li>
          </ul>

          <h1 className='p-page-header l-margin_top95'><Trans i18nKey='airportMap' /></h1>
          <img
            className='p-image'
            src={airportMap}
            alt={'Airport Map'}
          />
          <img
            className='p-image p-airport-legend'
            src={airportLegends}
            alt={'Airport Legends'}
          />

        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(TaxiService);
