import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { EllipsisLoader } from '../../components/ellipsisLoader';

export default function ExternalPage() {
  const [isInit, setIsInit] = React.useState(false);
  const history = useHistory();
  const location = useLocation();

  // safeguard if user calls this page directly, route back to home page
  if (!location.state || !location.state.destinationUrl) {
    history.replace('/');
    return null;
  }

  const handleOnLoad = () => {
    // only trigger animation on initial load
    if (!isInit) {
      setIsInit(true);
    }
  }

  const getIframeClass = () => {
    return isInit ? 'c-external-iframe animate__fade-in' : 'c-external-iframe o-opacity__0';
  }

  return (
    <div className="l-position__relative">
      <iframe
        className={getIframeClass()}
        title='externalSite'
        src={location.state.destinationUrl}
        onLoad={handleOnLoad}
      />
      {!isInit && (
        <div className="c-iframe__loader l-flex__middle">
          <EllipsisLoader />
        </div>
      )}
    </div>
  );
}
