import * as React from 'react';
import { RouteItems } from '../routes/RouteItems';
import { BottomTabBarItem } from './bottomTabBarItem';

export const BottomTabBar = (props) => {
  const bottomTabItems = RouteItems.filter(item => item.bottomTabBarImage);
  const pathArray = props.location.pathname.split('/');
  const parentPath = pathArray[1] ? `/${pathArray[1]}` : '/';

  return (
    <div className="c-bottom-tab-bar">
      {bottomTabItems.map(route => {
        const active = parentPath !== '/' && route.config.path === parentPath;
        return (
          <BottomTabBarItem key={route.nameKey} route={route} isActive={active} />
        );
      })}
    </div>
  );
};
