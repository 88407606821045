import React from 'react';
import ButtonGrid from '../../components/buttonGrid';
import { Destinations } from '../../utils/destinations';

export default function Visit() {
  return (
    <div>
      <ButtonGrid
        links={[
          {
            titleKey: 'placesToGo',
            to: {
              pathname: '/external',
              state: {
                destinationUrl: Destinations.placesToGo,
              },
            },
            svgName: 'tile-places-to-go',
          },
          {
            titleKey: 'thingsToDo',
            to: {
              pathname: '/external',
              state: {
                destinationUrl: Destinations.thingsToDo,
              },
            },
            svgName: 'tile-things-to-do',
          },
          {
            titleKey: 'events',
            to: {
              pathname: '/external',
              state: {
                destinationUrl: Destinations.events,
              },
            },
            svgName: 'tile-events',
          },
          {
            titleKey: 'planYourTrip',
            to: {
              pathname: '/external',
              state: {
                destinationUrl: Destinations.planYourTrip,
              },
            },
            svgName: 'tile-plan-your-trip',
          },
        ]}
        buttonColorStyle='background-color-sky-blue'
      />
    </div>
  );
}
