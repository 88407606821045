// svgIcon: svgIcon class to be used
// desc: weather condition representation (from design, for reference only)
// group: list of REST icon classes that belong to this UI weather icon
export const weatherIconMapping = [
    {
        svgIcon: 'weather-few-clouds-windy',
        desc: 'fair/clear and windy, a few clouds and windy',
        group: [
            'wind_skc', // fair/clear and windy
            'wind_few', // a few clouds and windy
        ]
    },
    {
        svgIcon: 'weather-windy-cloudy',
        desc: 'partly cloudy and windy, mostly cloudy and windy, overcast and windy',
        group: [
            'wind_sct', // partly cloudy and windy
            'wind_bkn', // mostly cloudy and windy
            'wind_ovc', // overcast and windy
        ]
    },
    {
        svgIcon: 'weather-cloudy',
        desc: 'mostly cloudy, overcast',
        group: [
            'bkn', // mostly cloudy
            'ovc', // overcast
        ]
    },
    {
        svgIcon: 'weather-sunny',
        desc: 'fair/clear, hot',
        group: [
            'skc', // fair/clear
            'hot', // hot
        ]
    },
    {
        svgIcon: 'weather-ice',
        desc: 'cold, blizzard',
        group: [
            'cold', // cold
            'blizzard', // blizzard
        ]
    },
    {
        svgIcon: 'weather-rain',
        desc: 'rain/snow, rain/sleet, freezing rain, rain/freezing rain, freezing rain/snow, sleet, rain',
        group: [
            'rain_snow', // rain/snow
            'rain_sleet', // rain/sleet
            'snow_sleet', // rain/sleet
            'fzra', // freezing rain
            'rain_fzra', // rain/freezing rain
            'snow_fzra', // freezing rain/snow
            'sleet', // sleet
            'rain', // rain
        ]
    },
    {
        svgIcon: 'weather-cloudy-rainy',
        desc: 'rain showers (high cloud cover), rain showers (low cloud cover)',
        group: [
            'rain_showers', // rain showers (high cloud cover)
            'rain_showers_hi', // rain showers (low cloud cover)
        ]
    },
    {
        svgIcon: 'weather-thunderstorm',
        desc: 'thunderstorm (high cloud cover), thunderstorm (medium cloud cover), thunderstorm (low cloud cover)',
        group: [
            'tsra', // thunderstorm (high cloud cover)
            'tsra_sct', // thunderstorm (medium cloud cover)
            'tsra_hi', // thunderstorm (low cloud cover)
        ]
    },
    {
        svgIcon: 'weather-fog',
        desc: 'dust, smoke, haze, fog/mist',
        group: [
            'dust', // dust
            'smoke', // smoke
            'haze', // haze
            'fog', // fog/mist
        ]
    },
    {
        svgIcon: 'weather-partly-cloudy',
        desc: 'a few clouds, partly cloudy',
        group: [
            'few', // a few clouds
            'sct', // party cloudy
        ]
    },
    {
        svgIcon: 'weather-snow',
        desc: 'snow',
        group: [
            'snow',
        ]
    },
    {
        svgIcon: 'weather-windy',
        desc: 'tornado, hurricane conditions, tropical storm conditions',
        group: [
            'tornado', // tornado
            'hurricane', // hurricane conditions
            'tropical_storm', // tropical storm conditions
        ]
    }
]