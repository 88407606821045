import React from "react";
import { Trans } from "react-i18next";
import { withRouter } from 'react-router-dom';
import ButtonGrid from '../components/buttonGrid';
import { Destinations } from '../utils/destinations';

class AirportShuttle extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1 className='color-white l-margin__left68 l-margin_bottom60'><Trans i18nKey='airportShuttle' /></h1>
        <ButtonGrid
          links={[
            {
              titleKey: 'bookRide',
              to: {
                pathname: '/transit/external',
                state: {
                  destinationUrl: Destinations.bookRide,
                },
              },
              svgName: 'tile-airport-shuttle',
            },
            {
              titleKey: 'reviewReservation',
              to: {
                pathname: '/transit/external',
                state: {
                  destinationUrl: Destinations.reviewReservation,
                },
              },
              svgName: 'tile-reservation',
            },
          ]}
          buttonColorStyle='background-color-green'
        />
      </React.Fragment>
    );
  }
}

export default withRouter(AirportShuttle);
