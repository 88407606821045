import React from 'react';
import ButtonGrid from '../../components/buttonGrid';
import { Destinations } from '../../utils/destinations';
import NewAirportTile from '../../components/customTiles/newAirportTile';
import { COLORS } from '../../constants/appConstants';

export default function Home() {

  return (
    <div className="l-page-content__standard">
      <ButtonGrid links={[
        {
          titleKey: 'transit',
          to: '/transit',
          svgName: 'tile-transit',
          colorStyle: 'background-color-green',
        },
        {
          titleKey: 'stay',
          to: {
            pathname: '/external',
            state: {
              destinationUrl: Destinations.stay,
            },
          },
          svgName: 'tile-stay',
          colorStyle: 'background-color-red',
        },
        {
          titleKey: 'visit',
          to: '/visit',
          svgName: 'tile-visit',
          colorStyle: 'background-color-sky-blue',
        },
        {
          titleKey: 'information',
          to: '/information',
          svgName: 'tile-info',
          svgFill: COLORS.BLACK,
          colorStyle: 'background-color-yellow',
        },
      ]} />
      <NewAirportTile />
    </div>
  );
}
