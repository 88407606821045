
import strings from '../../i18n/en'
import { getKeyByValue } from '../../utils/utils'

const translations = strings.translations;

export const shareRideServices = [
    {
        name: getKeyByValue(translations, translations.sharedRideServicesAirLinkNewYork),
        phone: '1 (877) 599-8200',
        link: 'https://www.goairlinkshuttle.com/'
    },
    {
        name: getKeyByValue(translations, translations.sharedRideServicesAllCountyExpress),
        phone: '1 (800) 914-4223',
        link: 'http://www.allcountyexpress.com/'
    },
    {
        name: getKeyByValue(translations, translations.sharedRideServicesETSAirShuttle),
        phone: '1 (718) 221-5341',
        link: 'https://www.etsairportshuttle.com/'
    },
    {
        name: getKeyByValue(translations, translations.sharedRideServicesPrimeTimeShuttle),
        phone: '1 (800) 377-8745',
        link: 'http://www.2theairport.com/'
    }
] 