import { COLORS } from '../constants/appConstants';
import Home from '../pages/Home';
import Transit from '../pages/transit';
import Visit from '../pages/Visit';
import { Information } from '../pages/information';
import ExternalPage from '../pages/ExternalPage';
import AirportShuttle from '../pages/airportShuttle';
import rentalCarService from '../pages/rentalCarService';
import taxiService from '../pages/taxiService';
import carService from '../pages/carService';
import { Destinations } from '../utils/destinations';

/*
  config - contains react-router-dom properties
  nameKey - define i18n namekey or leave it blank if this is not visible in menus
  buttonGrid - TBD
  bottomTabBarImage - define SVG image name if it is part of bottom tab bar or leave it empty
  bottomTabBarState? - optional state to pass to route for button tab bar menu item
  showBottomTabBar - boolean flag if this page should show bottom tab bar
  colorTheme - define color theme for this page (used in menus)
  noScrollBar? - optional boolean to disable overflow y scroll bar (used mostly on page with iFrame)
*/
export const RouteItems = [
  {
    config: {
      component: Home,
      exact: true,
      path: '/',
    },
    nameKey: 'home',
    buttonGrid: false,
    bottomTabBarImage: 'bottom-tab-bar-home',
    showBottomTabBar: false,
    colorTheme: '#FFCD00',
  },
  {
    config: {
      component: Transit,
      exact: true,
      path: '/transit',
    },
    nameKey: 'transit',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-transit',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/stay',
    },
    nameKey: 'stay',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-stay',
    bottomTabBarState: {
      destinationUrl: Destinations.stay,
    },
    showBottomTabBar: true,
    colorTheme: COLORS.RED,
  },
  {
    config: {
      component: Visit,
      exact: true,
      path: '/visit',
    },
    nameKey: 'visit',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-visit',
    showBottomTabBar: true,
    colorTheme: COLORS.SKY_BLUE,
  },
  {
    config: {
      component: Information,
      exact: true,
      path: '/information',
    },
    nameKey: 'information',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-info',
    showBottomTabBar: true,
    colorTheme: COLORS.YELLOW,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/information/external',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.YELLOW,
    noScrollBar: true, // use iframe scrollbar instead
  },
  {
    config: {
      component: Transit, // @TODO
      exact: true,
      path: '/airport',
    },
    nameKey: 'airport',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-airport',
    showBottomTabBar: true,
    colorTheme: COLORS.RED,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/external',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.YELLOW,
    noScrollBar: true,
  },
  {
    config: {
      component: AirportShuttle,
      exact: true,
      path: '/transit/airport-shuttle',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/transit/external',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
    noScrollBar: true,
  },
  {
    config: {
      component: rentalCarService,
      exact: true,
      path: '/transit/rental-car-service',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
  },
  {
    config: {
      component: taxiService,
      exact: true,
      path: '/transit/taxi-service',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
  },
  {
    config: {
      component: carService,
      exact: true,
      path: '/transit/car-service',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
  },
];
