import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import { RouteItems } from './RouteItems';
import Home from "../pages/Home";
import Wrapper from '../components/wrapper';

export default function Routes() {
  return (
    <Wrapper>
      <Switch>
        {RouteItems.map(route =>
          <Route key={route.nameKey} path={route.config.path} exact={route.config.exact} component={route.config.component} />
        )}
        {/* redirect user to home page if route does not exist. */}
        <Route component={Home} />
      </Switch>
    </Wrapper>
  );
}
