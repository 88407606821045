import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteItems } from '../routes/RouteItems';
import { BottomTabBar } from './bottomTabBar';
import { BottomLegalBar } from './bottomLegalBar';
import WelcomeHeader from '../components/welcomeHeader';
import NavigationControls from '../components/navigationControls';


class Wrapper extends React.Component {
  render() {
    const { location } = this.props;
    const routeItem = RouteItems.find(item => item.config.path === location.pathname);
    const showBottomTabBar = routeItem ? routeItem.showBottomTabBar : false;
    const isHomePage = location.pathname === '/';
    const pagePositionClass = isHomePage ? 'c-page-container-home-position' : 'c-page-container-normal-position';
    const pageOverflowClass = routeItem.noScrollBar ? 'l-overflow__hidden' : 'l-overflow__y';

    return (
      <React.Fragment>
        <NavigationControls
          hideBackButton={isHomePage}
        />
        <WelcomeHeader
          isHomePage={isHomePage}
        />
        <div className={`c-page-container ${pagePositionClass}`}>
          <div className={`c-page-content ${pageOverflowClass}`}>
            {this.props.children}
          </div>
        </div>
        {showBottomTabBar && <BottomTabBar location={location} />}
        {!showBottomTabBar && <BottomLegalBar />}
      </React.Fragment>
    );
  }
}

export default withRouter(Wrapper);
